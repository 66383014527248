import revive_payload_client_2eZ49wB6rb from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis@_7pmmsgmht3ubtcls2i6ff6vhma/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_H1yDbzVfZg from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis@_7pmmsgmht3ubtcls2i6ff6vhma/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CIsH8m7gpi from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis@_7pmmsgmht3ubtcls2i6ff6vhma/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_QphazJK1FY from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis@_7pmmsgmht3ubtcls2i6ff6vhma/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_CNGmy0LkON from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis@_7pmmsgmht3ubtcls2i6ff6vhma/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_91xrZA1MJO from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis@_7pmmsgmht3ubtcls2i6ff6vhma/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0KDJ3aaGg3 from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis@_7pmmsgmht3ubtcls2i6ff6vhma/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_XGI5dpnrEs from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis@_7pmmsgmht3ubtcls2i6ff6vhma/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_QxLK07vEKR from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_graphql-t_ow6ns72ty5lksfvgnfdmjy6i5u/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import sentry_client_shVUlIjFLk from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/sentry.client.ts";
import set_graphql_host_client_hEFjBexpur from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/set-graphql-host.client.ts";
import toasts_client_unuLMHjfa9 from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/toasts.client.ts";
export default [
  revive_payload_client_2eZ49wB6rb,
  unhead_H1yDbzVfZg,
  router_CIsH8m7gpi,
  payload_client_QphazJK1FY,
  navigation_repaint_client_CNGmy0LkON,
  check_outdated_build_client_91xrZA1MJO,
  chunk_reload_client_0KDJ3aaGg3,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_XGI5dpnrEs,
  plugin_QxLK07vEKR,
  sentry_client_shVUlIjFLk,
  set_graphql_host_client_hEFjBexpur,
  toasts_client_unuLMHjfa9
]