import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from 'graphql-request';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * ISO 3166-1 alpha-2 の国名コードで、2文字のアルファベット(大文字)
   * e.g.
   *   日本: JP
   *   アメリカ: US
   */
  Country: { input: any; output: any; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
  /** A valid URL, transported as a string */
  URL: { input: any; output: any; }
};

/** システムの種別 */
export enum AssignedServiceType {
  /** ブランドアプリ */
  Branded_app = 'branded_app',
  /** 予約 */
  Reserve = 'reserve',
  /** リテール */
  Retail = 'retail'
}

/** 利用店舗の検索条件 */
export type CustomerShopFilterInput = {
  /** 利用履歴がある店舗のUUID */
  shopUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** 顧客の検索条件 */
export type CustomersFilterInput = {
  /** 利用店舗の検索条件 */
  customerShops?: InputMaybe<Array<CustomerShopFilterInput>>;
  /** 顧客の回数券の検索条件 */
  issuedTicketBooks?: InputMaybe<Array<IssuedTicketBookFilterInput>>;
  /** キーワード検索の文字列 */
  keyword?: InputMaybe<Scalars['String']['input']>;
  /** 購入履歴の検索条件 */
  orders?: InputMaybe<Array<OrderFilterInput>>;
  /** 予約履歴の検索条件 */
  reservations?: InputMaybe<Array<ReservationFilterInput>>;
  /** 顧客の月謝の検索条件 */
  reserveSubscriptions?: InputMaybe<Array<ReserveSubscriptionFilterInput>>;
  /**
   * 予約回数の検索条件
   * @deprecated このパラメータは reservations に移行予定です
   */
  reservedCount?: InputMaybe<Array<ReservedCountFilterInput>>;
};

/** 顧客一覧のソート条件 */
export type CustomersSortInput = {
  /** 顧客一覧のソートキー */
  key: CustomersSortKey;
  /** 顧客一覧のソート方向 */
  order: SortOrder;
};

/** 顧客一覧のソートキー */
export enum CustomersSortKey {
  /** 初回購入日 */
  FIRST_ORDERED_AT = 'FIRST_ORDERED_AT',
  /** 最終購入日 */
  LAST_ORDERED_AT = 'LAST_ORDERED_AT',
  /** 前回の予約 */
  LAST_RESERVED_AT = 'LAST_RESERVED_AT',
  /** 次回の予約 */
  NEXT_RESERVED_AT = 'NEXT_RESERVED_AT',
  /** 購入回数（通算） */
  ORDERED_COUNT = 'ORDERED_COUNT',
  /** 顧客登録日 */
  REGISTERED_AT = 'REGISTERED_AT',
  /** 利用金額（通算） */
  TOTAL_SALES_AMOUNT = 'TOTAL_SALES_AMOUNT'
}

/** 検索の日時の条件 */
export type DateTimeRangeInput = {
  /** 指定された日時以降 */
  equalOrGreaterThan?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** 指定された日時以前 */
  equalOrLessThan?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** 指定された日時より後 */
  greaterThan?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** 指定された日時より前 */
  lessThan?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** 性別 */
export enum Gender {
  /** 男性 */
  MAN = 'MAN',
  /** その他 */
  OTHER = 'OTHER',
  /** 女性 */
  WOMAN = 'WOMAN'
}

/** 検索の数の条件 */
export type IntRangeInput = {
  /** 指定された数以上 */
  equalOrGreaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** 指定された数以下 */
  equalOrLessThan?: InputMaybe<Scalars['Int']['input']>;
  /** 指定された数と等しい */
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  /** 指定された数より大きい */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** 指定された数より小さい */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
};

/** 顧客の回数券の検索条件 */
export type IssuedTicketBookFilterInput = {
  /** 回数券の有効期限 */
  expiresAtRange?: InputMaybe<DateTimeRangeInput>;
  /** 回数券のソースID */
  sourceIdentifiers?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** 回数券の残回数 */
  ticketCountRange?: InputMaybe<IntRangeInput>;
};

/** 購入履歴の検索条件 */
export type OrderFilterInput = {
  /** 初回購入日 */
  firstOrderedAtRange?: InputMaybe<DateTimeRangeInput>;
  /** 最終購入日 */
  lastOrderedAtRange?: InputMaybe<DateTimeRangeInput>;
  /** 購入回数 */
  orderedCountRange?: InputMaybe<IntRangeInput>;
  /** 店舗のUUID */
  shopUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** 利用金額 */
  totalSalesAmountRange?: InputMaybe<IntRangeInput>;
};

/** 予約履歴の検索条件 */
export type ReservationFilterInput = {
  /** 前回予約の日時 */
  lastReservationCheckinAtRange?: InputMaybe<DateTimeRangeInput>;
  /** 次回予約の日時 */
  nextReservationCheckinAtRange?: InputMaybe<DateTimeRangeInput>;
  /** 予約回数 */
  reservedCountRange?: InputMaybe<IntRangeInput>;
  /** 予約された店舗のUUID */
  shopUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** 顧客の月謝の検索条件 */
export type ReserveSubscriptionFilterInput = {
  /** 事業者の月謝のID */
  planIdentifiers?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** 予約回数の検索条件 */
export type ReservedCountFilterInput = {
  /** 予約回数 */
  countRange?: InputMaybe<IntRangeInput>;
  /** 予約された店舗のUUID */
  shopUuids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** セカンドパーティ顧客の種別 */
export enum SecondPartyType {
  /** ブランドアプリ */
  BRANDED_APP = 'BRANDED_APP',
  /** 予約 */
  RESERVE = 'RESERVE',
  /** リテール */
  RETAIL = 'RETAIL'
}

/** ソート方向 */
export enum SortOrder {
  /** 昇順 */
  ASC = 'ASC',
  /** 降順 */
  DESC = 'DESC'
}

/** Autogenerated input type of UpdateCustomersViewSetting */
export type UpdateCustomersViewSettingInput = {
  /** 事業者のUUID */
  businessEntityUuid: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 顧客一覧の表示設定 */
  customersViewSetting: Scalars['JSON']['input'];
};

export type GetBusinessEntityQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type GetBusinessEntityQuery = { businessEntity: { __typename: 'BusinessEntity', uuid: string, ticketBookSources: { __typename: 'BadRequest', message: string } | { __typename: 'TemporaryUnavailable', message: string } | { __typename: 'TicketBookSources', nodes: Array<{ identifier: string, name: string, imageUrl?: any | null, isSharedAmongShops: boolean, isPublished: boolean, availableAt: Array<{ uuid: string, name: string }> }> }, reserveSubscriptionPlans: { __typename: 'BadRequest', message: string } | { __typename: 'ReserveSubscriptionPlans', nodes: Array<{ identifier: string, name: string, imageUrl?: any | null, isSharedAmongShops: boolean, isPublished: boolean, availableAt: Array<{ uuid: string, name: string }> }> } | { __typename: 'TemporaryUnavailable', message: string }, shops: Array<{ uuid: string, name: string }> } | { __typename: 'Forbidden', message: string, errorCode: number } | { __typename: 'NotFound', message: string, errorCode: number } };

export type GetBusinessEntityAssignedServiceTypesQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type GetBusinessEntityAssignedServiceTypesQuery = { businessEntity: { __typename: 'BusinessEntity', uuid: string, assignedServiceTypes: Array<AssignedServiceType> } | { __typename: 'Forbidden', message: string, errorCode: number } | { __typename: 'NotFound', message: string, errorCode: number } };

export type GetBusinessEntityReserveSubscriptionPlansQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type GetBusinessEntityReserveSubscriptionPlansQuery = { businessEntity: { __typename: 'BusinessEntity', uuid: string, reserveSubscriptionPlans: { __typename: 'BadRequest', message: string } | { __typename: 'ReserveSubscriptionPlans', nodes: Array<{ identifier: string, name: string, imageUrl?: any | null, isSharedAmongShops: boolean, isPublished: boolean, availableAt: Array<{ uuid: string, name: string }> }> } | { __typename: 'TemporaryUnavailable', message: string } } | { __typename: 'Forbidden', message: string, errorCode: number } | { __typename: 'NotFound', message: string, errorCode: number } };

export type GetBusinessEntityShopsQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type GetBusinessEntityShopsQuery = { businessEntity: { __typename: 'BusinessEntity', uuid: string, shops: Array<{ uuid: string, name: string }> } | { __typename: 'Forbidden', message: string, errorCode: number } | { __typename: 'NotFound', message: string, errorCode: number } };

export type GetBusinessEntityTicketBookSourcesQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type GetBusinessEntityTicketBookSourcesQuery = { businessEntity: { __typename: 'BusinessEntity', uuid: string, ticketBookSources: { __typename: 'BadRequest', message: string } | { __typename: 'TemporaryUnavailable', message: string } | { __typename: 'TicketBookSources', nodes: Array<{ identifier: string, name: string, imageUrl?: any | null, isSharedAmongShops: boolean, isPublished: boolean, availableAt: Array<{ uuid: string, name: string }> }> } } | { __typename: 'Forbidden', message: string, errorCode: number } | { __typename: 'NotFound', message: string, errorCode: number } };

export type GetCustomerQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type GetCustomerQuery = { customer: { __typename: 'Customer', profile?: { email?: string | null, name?: string | null } | null, reservationsSummary?: { shops: Array<{ reservedCount: number, lastReservationCheckinAt?: any | null, nextReservationCheckinAt?: any | null, shop: { __typename: 'NotFound' } | { __typename: 'Shop', name: string, uuid: string } }> } | null } | { __typename: 'NotFound', message: string, errorCode: number } };

export type GetCustomersQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomersFilterInput>;
  sort?: InputMaybe<CustomersSortInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCustomersQuery = { businessEntity: { __typename: 'BusinessEntity', customers: { totalCount: number, edges?: Array<{ node?: { uuid: string, registeredAt: any, profile?: { email?: string | null, name?: string | null, administrativeName?: string | null, fullName?: string | null, birthday?: any | null, gender?: Gender | null, phoneNumber?: string | null } | null, shops: Array<{ __typename: 'NotFound' } | { __typename: 'Shop', uuid: string, name: string }>, issuedTicketBooks: Array<{ expiresAt: any, identifier: string, invalidatedAt?: any | null, ticketCount: number, issuedShop: { __typename: 'NotFound' } | { __typename: 'Shop', name: string, uuid: string }, source: { __typename: 'BadRequest' } | { __typename: 'NotFound' } | { __typename: 'TemporaryUnavailable' } | { __typename: 'TicketBookSource', identifier: string, name: string, imageUrl?: any | null } }>, reserveSubscriptions: Array<{ expiresAt?: any | null, identifier: string, isBilled: boolean, subscribedTo: { __typename: 'NotFound' } | { __typename: 'Shop', name: string, uuid: string }, plan: { __typename: 'BadRequest' } | { __typename: 'NotFound' } | { __typename: 'ReserveSubscriptionPlan', identifier: string, name: string, imageUrl?: any | null, availableAt: Array<{ uuid: string, name: string }> } | { __typename: 'TemporaryUnavailable' } }>, reservationsSummary?: { nextReservationCheckinAt?: any | null, nextReservationCheckoutAt?: any | null, lastReservationCheckinAt?: any | null, lastReservationCheckoutAt?: any | null } | null, ordersSummary?: { firstOrderedAt?: any | null, lastOrderedAt?: any | null, orderedCount: number, totalSalesAmount: number } | null } | null } | null> | null, pageInfo: { endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } } | { __typename: 'Forbidden', message: string, errorCode: number } | { __typename: 'NotFound', message: string, errorCode: number } };

export type GetCustomersViewSettingQueryVariables = Exact<{
  uuid: Scalars['ID']['input'];
}>;


export type GetCustomersViewSettingQuery = { businessEntity: { __typename: 'BusinessEntity', customersViewSetting?: any | null } | { __typename: 'Forbidden' } | { __typename: 'NotFound' } };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { me: { u: string } };

export type UpdateCustomersViewSettingMutationVariables = Exact<{
  input: UpdateCustomersViewSettingInput;
}>;


export type UpdateCustomersViewSettingMutation = { result: { __typename: 'NotFound' } | { __typename: 'Unit' } };


export const GetBusinessEntityDocument = gql`
    query GetBusinessEntity($uuid: ID!) {
  businessEntity(uuid: $uuid) {
    __typename
    ... on BusinessEntity {
      __typename
      uuid
      ticketBookSources {
        __typename
        ... on BaseError {
          message
        }
        ... on TicketBookSources {
          nodes {
            identifier
            name
            imageUrl
            isSharedAmongShops
            availableAt {
              uuid
              name
            }
            isPublished
          }
        }
      }
      reserveSubscriptionPlans {
        __typename
        ... on BaseError {
          message
        }
        ... on ReserveSubscriptionPlans {
          nodes {
            identifier
            name
            imageUrl
            isSharedAmongShops
            availableAt {
              uuid
              name
            }
            isPublished
          }
        }
      }
      shops {
        uuid
        name
      }
    }
    ... on BaseError {
      message
      errorCode
    }
  }
}
    `;
export const GetBusinessEntityAssignedServiceTypesDocument = gql`
    query GetBusinessEntityAssignedServiceTypes($uuid: ID!) {
  businessEntity(uuid: $uuid) {
    __typename
    ... on BusinessEntity {
      __typename
      uuid
      assignedServiceTypes
    }
    ... on BaseError {
      message
      errorCode
    }
  }
}
    `;
export const GetBusinessEntityReserveSubscriptionPlansDocument = gql`
    query GetBusinessEntityReserveSubscriptionPlans($uuid: ID!) {
  businessEntity(uuid: $uuid) {
    __typename
    ... on BusinessEntity {
      __typename
      uuid
      reserveSubscriptionPlans {
        __typename
        ... on BaseError {
          message
        }
        ... on ReserveSubscriptionPlans {
          nodes {
            identifier
            name
            imageUrl
            isSharedAmongShops
            availableAt {
              uuid
              name
            }
            isPublished
          }
        }
      }
    }
    ... on BaseError {
      message
      errorCode
    }
  }
}
    `;
export const GetBusinessEntityShopsDocument = gql`
    query GetBusinessEntityShops($uuid: ID!) {
  businessEntity(uuid: $uuid) {
    __typename
    ... on BusinessEntity {
      __typename
      uuid
      shops {
        uuid
        name
      }
    }
    ... on BaseError {
      message
      errorCode
    }
  }
}
    `;
export const GetBusinessEntityTicketBookSourcesDocument = gql`
    query GetBusinessEntityTicketBookSources($uuid: ID!) {
  businessEntity(uuid: $uuid) {
    __typename
    ... on BusinessEntity {
      __typename
      uuid
      ticketBookSources {
        __typename
        ... on BaseError {
          message
        }
        ... on TicketBookSources {
          nodes {
            identifier
            name
            imageUrl
            isSharedAmongShops
            availableAt {
              uuid
              name
            }
            isPublished
          }
        }
      }
    }
    ... on BaseError {
      message
      errorCode
    }
  }
}
    `;
export const GetCustomerDocument = gql`
    query GetCustomer($uuid: ID!) {
  customer(uuid: $uuid) {
    __typename
    ... on Customer {
      profile {
        email
        name
      }
      reservationsSummary {
        shops {
          shop {
            __typename
            ... on Shop {
              name
              uuid
            }
          }
          reservedCount
          lastReservationCheckinAt
          nextReservationCheckinAt
        }
      }
    }
    ... on BaseError {
      message
      errorCode
    }
  }
}
    `;
export const GetCustomersDocument = gql`
    query GetCustomers($uuid: ID!, $cursor: String, $filter: CustomersFilterInput, $sort: CustomersSortInput, $first: Int) {
  businessEntity(uuid: $uuid) {
    __typename
    ... on BusinessEntity {
      customers(after: $cursor, filter: $filter, sort: $sort, first: $first) {
        edges {
          node {
            uuid
            profile {
              email
              name
              administrativeName
              fullName
              birthday
              gender
              phoneNumber
            }
            registeredAt
            shops {
              __typename
              ... on Shop {
                uuid
                name
              }
            }
            issuedTicketBooks {
              expiresAt
              identifier
              invalidatedAt
              issuedShop {
                __typename
                ... on Shop {
                  name
                  uuid
                }
              }
              source {
                __typename
                ... on TicketBookSource {
                  identifier
                  name
                  imageUrl
                }
              }
              ticketCount
            }
            reserveSubscriptions {
              expiresAt
              identifier
              isBilled
              subscribedTo {
                __typename
                ... on Shop {
                  name
                  uuid
                }
              }
              plan {
                __typename
                ... on ReserveSubscriptionPlan {
                  identifier
                  name
                  imageUrl
                  availableAt {
                    uuid
                    name
                  }
                }
              }
            }
            reservationsSummary {
              nextReservationCheckinAt
              nextReservationCheckoutAt
              lastReservationCheckinAt
              lastReservationCheckoutAt
            }
            ordersSummary {
              firstOrderedAt
              lastOrderedAt
              orderedCount
              totalSalesAmount
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
    ... on BaseError {
      message
      errorCode
    }
  }
}
    `;
export const GetCustomersViewSettingDocument = gql`
    query GetCustomersViewSetting($uuid: ID!) {
  businessEntity(uuid: $uuid) {
    __typename
    ... on BusinessEntity {
      customersViewSetting
    }
  }
}
    `;
export const GetMeDocument = gql`
    query GetMe {
  me {
    u
  }
}
    `;
export const UpdateCustomersViewSettingDocument = gql`
    mutation UpdateCustomersViewSetting($input: UpdateCustomersViewSettingInput!) {
  result: updateCustomersViewSetting(input: $input) {
    __typename
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetBusinessEntity(variables: GetBusinessEntityQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBusinessEntityQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBusinessEntityQuery>(GetBusinessEntityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBusinessEntity', 'query', variables);
    },
    GetBusinessEntityAssignedServiceTypes(variables: GetBusinessEntityAssignedServiceTypesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBusinessEntityAssignedServiceTypesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBusinessEntityAssignedServiceTypesQuery>(GetBusinessEntityAssignedServiceTypesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBusinessEntityAssignedServiceTypes', 'query', variables);
    },
    GetBusinessEntityReserveSubscriptionPlans(variables: GetBusinessEntityReserveSubscriptionPlansQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBusinessEntityReserveSubscriptionPlansQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBusinessEntityReserveSubscriptionPlansQuery>(GetBusinessEntityReserveSubscriptionPlansDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBusinessEntityReserveSubscriptionPlans', 'query', variables);
    },
    GetBusinessEntityShops(variables: GetBusinessEntityShopsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBusinessEntityShopsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBusinessEntityShopsQuery>(GetBusinessEntityShopsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBusinessEntityShops', 'query', variables);
    },
    GetBusinessEntityTicketBookSources(variables: GetBusinessEntityTicketBookSourcesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBusinessEntityTicketBookSourcesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBusinessEntityTicketBookSourcesQuery>(GetBusinessEntityTicketBookSourcesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBusinessEntityTicketBookSources', 'query', variables);
    },
    GetCustomer(variables: GetCustomerQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCustomerQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCustomerQuery>(GetCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCustomer', 'query', variables);
    },
    GetCustomers(variables: GetCustomersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCustomersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCustomersQuery>(GetCustomersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCustomers', 'query', variables);
    },
    GetCustomersViewSetting(variables: GetCustomersViewSettingQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCustomersViewSettingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCustomersViewSettingQuery>(GetCustomersViewSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCustomersViewSetting', 'query', variables);
    },
    GetMe(variables?: GetMeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMeQuery>(GetMeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMe', 'query', variables);
    },
    UpdateCustomersViewSetting(variables: UpdateCustomersViewSettingMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCustomersViewSettingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCustomersViewSettingMutation>(UpdateCustomersViewSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCustomersViewSetting', 'mutation', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;