<script setup lang="ts">
import { StandButton } from '@heyinc/stand-ui-vue'

import NavigationHeader from '~/components/shared/NavigationHeader.vue'

import type { NuxtError } from '#app'
import { useRouter } from '#imports'

defineProps<{ error: NuxtError }>()

const handleClick = () => {
  const router = useRouter()

  if (window.history.length > 2) {
    router.back()
  }

  window.location.href = '/platform/redirect'
}
</script>

<template>
  <div class="grid h-screen grid-rows-[max-content_auto]">
    <NavigationHeader />
    <div class="grid place-items-center px-3 pb-8">
      <div class="flex flex-col items-center">
        <p class="text-24 font-bold pc:text-36">
          <template v-if="error.statusCode === 404">
            ページが見つかりません
          </template>
          <template v-else>
            ページが動作していません
          </template>
        </p>
        <p class="mt-2 text-center text-12 text-gray-400 pc:mt-3 pc:text-16">
          <template v-if="error.statusCode === 404">
            お探しのページが見つかりませんでした。<br>
            URLが間違っているか、削除された可能性があります。
          </template>
          <template v-else>
            アクセスが集中しているか、サーバーエラーが発生している可能性があります。<br>
            時間をおいてからもう一度アクセスしてください。
          </template>
        </p>
        <StandButton
          class="mt-3 pc:mt-5"
          @click="handleClick"
        >
          戻る
        </StandButton>
      </div>
    </div>
  </div>
</template>
