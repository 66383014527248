// トーストは各所から投げっぱなしにしたいので、 success() や error() から投げっぱなしにできるような
// Composition API (Toasts) を提供するモジュール

import { inject, provide, ref, type InjectionKey } from 'vue'

const MAX_TOASTS_COUNT = 5

export type Toasts = {
  list(): Toast[]
  success(message: string): void
  error(message: string): void
  remove(id: number): void
}

export type Toast = {
  id: number
  kind: 'success' | 'error'
  message: string
}

const toastsKey = Symbol() as InjectionKey<Toasts>

export function useToasts(): Toasts | undefined {
  return inject(toastsKey)
}

// トーストの状態はVueの provide/inject APIを用いてVueアプリケーションのコンテキストに乗せて機能させる
// * plugins/toasts.client.ts でVueアプリケーションレベルに注入している
// * `toasts.list()` の表示も layous/default.vue で行うことで、すべてのページで無設定で利用可能にしている
export function provideToasts(provide_ = provide) {
  let nextId = 0
  const list = ref<Toast[]>([])
  const push = (data: Omit<Toast, 'id'>) => {
    const id = nextId++
    if (MAX_TOASTS_COUNT <= list.value.length) list.value.shift()
    list.value.push({ id, ...data })
  }

  provide_(toastsKey, {
    list() {
      return list.value
    },
    success(message: string) {
      push({ kind: 'success', message })
    },
    error(message: string) {
      push({ kind: 'error', message })
    },
    remove(id: number) {
      const index = list.value.findIndex(toast => toast.id === id)
      list.value.splice(index, 1)
    },
  })
}
